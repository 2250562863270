import { ButtonTheme } from '@/components/Button/theme';
import { tw } from '@/utils/tw';

const Button = tw.theme({
  extend: ButtonTheme,
  base: 'self-center',
});

const KlarnaPayment = tw.theme({
  slots: {
    base: [],
    content: ['flex', 'flex-col'],
  },
});

export default Object.assign(KlarnaPayment, { Button });
