import { tw } from '@/utils/tw';

const CheckoutTheme = tw.theme({
  slots: {
    base: ['flex', 'flex-col', 'gap-y-10'],
    closed: [],
  },
});

export default CheckoutTheme;
