import { Button as StandaloneButton } from '@/components/Button';
import { KlarnaPaymentTheme } from '@/components/KlarnaPayment/theme';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: KlarnaPaymentTheme });
const Base = $({ as: 'div', slot: 'base' });
const Content = $({ as: 'div', slot: 'content' });

const Button = GenericSlot({ as: StandaloneButton, theme: KlarnaPaymentTheme.Button });

export const KlarnaPayment = Object.assign(Base, {
  Content,
  Button,
});
