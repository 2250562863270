import { Button as StandaloneButton } from '@/components/Button';
import { Checkbox as StandaloneCheckbox } from '@/components/Checkbox';
import { CheckoutFormTheme } from '@/components/CheckoutForm/theme';
import { KlarnaPayment as StandaloneKlarnaPayment } from '@/components/KlarnaPayment';
import { TextField as StandaloneTextField } from '@/components/TextField';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: CheckoutFormTheme });

const Base = $({ as: 'form', slot: 'base' });
const Policy = $({ as: 'p', slot: 'policy' });

const Button = GenericSlot({ as: StandaloneButton, theme: CheckoutFormTheme.Button });
const Checkbox = GenericSlot({ as: StandaloneCheckbox, theme: CheckoutFormTheme.Checkbox });
const KlarnaPayment = GenericSlot({ as: StandaloneKlarnaPayment, theme: CheckoutFormTheme.KlarnaPayment });
const TextField = GenericSlot({ as: StandaloneTextField, theme: CheckoutFormTheme.TextField });

export const CheckoutForm = Object.assign(Base, { Policy, TextField, Checkbox, Button, KlarnaPayment });
