import useUserAuth from '@/hooks/useUserAuth';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { logger } from '@/utils/logger';
import { mergeOptions } from '@/utils/merge';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import { ReactNode, useCallback } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { onPaymentEvent, SuccessPaymentResponse } from '../KlarnaPayment';
import { CheckoutForm } from './CheckoutForm';
import useOrderCreate from '@/hooks/useOrderCreate';

interface StandaloneCheckoutFormProps extends StandaloneComponentProps<typeof CheckoutForm> {
  labels?: {
    email?: ReactNode;
    firstName?: ReactNode;
    lastName?: ReactNode;
    newsletter?: ReactNode;
  };
  submitButtonContent?: ReactNode;
  policy?: ReactNode;
}

const checkoutSchema = z.object({
  firstName: z
    .string()
    .nonempty('* Detta fält är obligatoriskt')
    .min(2, '* Fältet ska innehålla minst 2 tecken och högst 255 tecken')
    .max(255, '* Fältet ska innehålla minst 2 tecken och högst 255 tecken'),
  lastName: z
    .string()
    .nonempty('* Detta fält är obligatoriskt')
    .min(2, '* Fältet ska innehålla minst 2 tecken och högst 255 tecken')
    .max(255, '* Fältet ska innehålla minst 2 tecken och högst 255 tecken'),
  email: z.string().nonempty('* Var vänlig ange din e-postadress').email('* Vänligen ange en giltig e-postadress'),
  newsletter: z.boolean(),
});

const paymentContainerId = 'payment-container';

export const StandaloneCheckoutForm: StandaloneComponent<StandaloneCheckoutFormProps> = ({
  labels,
  policy,
  submitButtonContent,
  ...props
}) => {
  const { push } = useRouter();
  const { createOrder } = useOrderCreate();
  const { alltIdUserCode, createPasswordlessAccount } = useUserAuth();
  const { handleSubmit, setError, register, formState } = useForm<z.infer<typeof checkoutSchema>>({
    resolver: zodResolver(checkoutSchema),
  });

  const createAccount: SubmitHandler<FieldValues> = async (formData) => {
    const { firstName, lastName, email } = formData;
    const subscription = Boolean(formData.newsletter);

    try {
      await createPasswordlessAccount({ firstName, lastName, email }, subscription);
    } catch (err) {
      logger.error(err);

      setError('root.serverError', { message: '* Konto kan inte skapas' });
      return;
    }
  };

  const onKlarnaPaymentSuccess = useCallback(
    async (response: SuccessPaymentResponse) => {
      await createOrder(alltIdUserCode!, response.token);
      await push('/confirmation');
      window.scrollTo({ top: 0, behavior: 'instant' });
    },
    [push, createOrder, alltIdUserCode],
  );

  const onKlarnaPaymentFailure = useCallback(
    async (e?: onPaymentEvent) => {
      setError('root.serverError', { message: e?.message });
    },
    [setError],
  );

  return (
    <CheckoutForm onSubmit={handleSubmit(createAccount)} {...props}>
      <CheckoutForm.TextField
        caption={formState.errors.email?.message}
        label={labels?.email}
        placeholder="E-post"
        status={formState.errors.email ? 'error' : 'default'}
        options={mergeOptions({ $input: { ...register('email') } }, props.options?.$textField)}
      />
      <CheckoutForm.TextField
        caption={formState.errors.firstName?.message}
        label={labels?.firstName}
        placeholder="Förnamn"
        status={formState.errors.firstName ? 'error' : 'default'}
        options={mergeOptions({ $input: { ...register('firstName') } }, props.options?.$textField)}
      />
      <CheckoutForm.TextField
        caption={formState.errors.lastName?.message}
        label={labels?.lastName}
        placeholder="Efternamn"
        status={formState.errors.lastName ? 'error' : 'default'}
        options={mergeOptions({ $input: { ...register('lastName') } }, props.options?.$textField)}
      />
      <CheckoutForm.Checkbox
        caption={formState.errors.newsletter?.message}
        label={labels?.newsletter}
        options={mergeOptions({ $input: { ...register('newsletter') } }, props.options?.$checkbox)}
      />
      {policy && <CheckoutForm.Policy>{policy}</CheckoutForm.Policy>}
      {submitButtonContent && (
        <CheckoutForm.Button
          content={submitButtonContent}
          options={mergeOptions({ type: 'submit', size: 'large' } as const, props.options?.$button)}
        />
      )}
      <CheckoutForm.KlarnaPayment
        button={{ content: 'Betala', options: { size: 'large' } }}
        handleFailure={onKlarnaPaymentFailure}
        handleSuccess={onKlarnaPaymentSuccess}
        paymentContainerId={paymentContainerId}
        options={props.options?.$klarnaPayment}
      />
    </CheckoutForm>
  );
};
