import { CheckoutTheme } from '@/components/Checkout/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: CheckoutTheme });

const Section = $({ as: 'section', slot: 'base' });
const Closed = $({ as: 'div', slot: 'closed' });

export const Checkout = Object.assign(Section, {
  Closed,
});
