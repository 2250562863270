import { ButtonTheme } from '@/components/Button/theme';
import { CheckboxTheme } from '@/components/Checkbox/theme';
import { KlarnaPaymentTheme } from '@/components/KlarnaPayment/theme';
import { TextFieldTheme } from '@/components/TextField/theme';
import { tw } from '@/utils/tw';

const CheckoutFormTheme = tw.theme({
  slots: {
    base: [],
    policy: [],
  },
});

export default Object.assign(CheckoutFormTheme, {
  Button: ButtonTheme,
  Checkbox: CheckboxTheme,
  KlarnaPayment: KlarnaPaymentTheme,
  TextField: TextFieldTheme,
});
