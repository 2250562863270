import { Device, PageType } from '@/types/app';
import { http } from '@/utils/http';
import { logger } from '@/utils/logger';
import { getOcelotConfig } from 'lib/configs/ocelot';

interface InitializedPayment {
  client_token: string;
  payment_method_categories: {
    asset_urls: {
      descriptive: string;
      standard: string;
    };
    identifier: string;
    name: string;
  }[];
}

export const initPaymentRequest = async (alltIdUserCode: string) => {
  const { enabled } = getOcelotConfig('features.payments');

  if (!enabled) {
    throw new Error(`Payments must be enabled`);
  }

  const response = await http.post<InitializedPayment>('/api/payments/create-klarna-session', {
    alltIdUserCode,
  });

  const { data } = response || {};

  if (!data) {
    logger.error(`initPaymentRequest: Cannot initialize payment for user: ${alltIdUserCode}`);
    throw new Error('Could not initialized payment request');
  }

  return data;
};

export const createOrderRequest = async (
  alltIdUserCode: string,
  authorizationToken: string,
  dataAnalytics?: {
    gaId?: string;
    pageId?: number;
    device?: Device;
    type?: PageType;
    publishedUrl?: string;
  },
) => {
  const { enabled } = getOcelotConfig('features.payments');

  if (!enabled) {
    throw new Error(`Payments must be enabled`);
  }

  const response = await http.post('/api/payments/create-klarna-order', {
    alltIdUserCode,
    authorizationToken,
    dataAnalytics,
  });

  const { data } = response || {};

  if (!data) {
    logger.error(`createOrderRequest: Cannot create payment order for the user: ${alltIdUserCode}`);
    throw new Error('Could not create order');
  }

  return data;
};
