/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneCheckoutForm as ResolvedStandaloneCheckoutForm } from 'base/components/standalone/CheckoutForm';

export const CheckoutForm: typeof ResolvedStandaloneCheckoutForm = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneCheckoutForm {...props} />
        </ErrorBoundary>
    );
});

export type CheckoutFormProps = PropsFromComponent<typeof CheckoutForm>;

/** @deprecated Use slot architecture instead */
export const CheckoutFormWith = (extras: DynamicStandaloneExtras): typeof CheckoutForm => {
    return function CheckoutForm(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'CheckoutForm');
        return <ResolvedStandaloneCheckoutForm {...mergeProps({ options: { theme } }, props)} />;
    }
}